const requestFullscreenProps = [
  'requestFullscreen',
  'mozRequestFullScreen',
  'msRequestFullScreen',
  'webkitRequestFullscreen',
  'webkitEnterFullscreen',
]

const browserFullscreenProps = [
  { isParentDocument: true, name: 'fullscreenElement' },
  { isParentDocument: true, name: 'mozFullScreenElement' },
  { isParentDocument: true, name: 'msFullscreenElement' },
  { isParentDocument: true, name: 'webkitFullscreenElement' },
  { isParentDocument: false, name: 'webkitDisplayingFullscreen' },
]
const getBrowserFullscreenElProp = (el: any) => {
  for (let i = 0; i < browserFullscreenProps.length; i++) {
    const { isParentDocument, name } = browserFullscreenProps[i]
    const parent = isParentDocument ? document : el

    if (typeof parent[name] !== 'undefined') {
      return name
    }
  }
  throw new Error('fullscreenElement is not supported by this browser')
}

const getRequestFullscreenProp = (el: any) => {
  for (let i = 0; i < requestFullscreenProps.length; i++) {
    const prop = requestFullscreenProps[i]

    if (typeof el[prop] !== 'undefined') {
      return prop
    }
  }

  throw new Error('requestFullscreen is not supported by this browser')
}

const setupCloseListeners = (el: any, event: string, cb: () => void) => {
  window.nillysVidCloseCb = cb

  if (event === 'webkitEnterFullscreen') {
    el.addEventListener('webkitendfullscreen', cb, false)
  } else if (event === 'webkitRequestFullscreen') {
    el.addEventListener('webkitfullscreenchange', cb)
  } else {
    el.addEventListener('fullscreenchange', cb)
  }
}

const removeCloseListeners = () => {
  document.removeEventListener('webkitendfullscreen', window.nillysVidCloseCb)
  document.removeEventListener(
    'webkitfullscreenchange',
    window.nillysVidCloseCb
  )
  document.removeEventListener('fullscreenchange', window.nillysVidCloseCb)
}

const addErrorListeners = (cb: () => void) => {
  window.nillysVidErrorCb = cb // Need to keep a reference to close event listeners
  document.addEventListener('fullscreenerror', cb)
  document.addEventListener('webkitfullscreenerror', cb)
  document.addEventListener('MSFullscreenError', cb)
}

const removeErrorListeners = () => {
  document.removeEventListener('fullscreenerror', window.nillysVidErrorCb)
  document.removeEventListener('webkitfullscreenerror', window.nillysVidErrorCb)
  document.removeEventListener('MSFullscreenError', window.nillysVidErrorCb)
}

const isFullscreen = (el: any) => {
  const fullscreenProp = getBrowserFullscreenElProp(el)

  const parent = fullscreenProp === 'webkitDisplayingFullscreen' ? el : document

  return parent[fullscreenProp]
}

export const exitFullscreen = (videoEl: any, closeFullscreen: () => void) => {
  const shouldExitFullscreen = !isFullscreen(videoEl)
  if (shouldExitFullscreen) {
    videoEl.pause()
    closeFullscreen()
  }

  // Always remove listeners on close
  removeErrorListeners()
  removeCloseListeners()
}

export const openFullscreen = (
  videoEl: any,
  closeFullscreen: () => void,
  autoplay: boolean = true
) => {
  try {
    addErrorListeners(() => {
      openInstagram(videoEl, closeFullscreen)
    })

    const requestFullscreenProp = getRequestFullscreenProp(videoEl)

    // Request fullscreen access
    if (requestFullscreenProp === 'webkitRequestFullscreen') {
      videoEl.webkitRequestFullscreen(Element['ALLOW_KEYBOARD_INPUT'])
    } else {
      videoEl[requestFullscreenProp]()
    }

    // Autoplay if fullscreen is successful
    if (autoplay) {
      videoEl.play().catch(() => {
        // catch error when user suddenly closes the video
        videoEl.pause()
      })
    }

    // Handle fullscreen close
    setupCloseListeners(videoEl, requestFullscreenProp, () =>
      exitFullscreen(videoEl, closeFullscreen)
    )
  } catch (error) {
    // Force quit fullscreen and manually set videoEl to null
    openInstagram(videoEl, closeFullscreen)
  }
}

// Open instagram for any unsupported devices
const openInstagram = (videoEl, closeFullscreen) => {
  if (videoEl) {
    exitFullscreen(videoEl, closeFullscreen)
  }

  window.open(
    'https://www.instagram.com/nillysburgershop/p/CPyrqxjgXyu/?utm_medium=copy_link'
  )
  return
}
