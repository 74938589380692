import React from 'react'
import Home from '@/components/pages/Home'

import { Layout } from '@/components'

export default () => {
  return (
    <Layout
      title="Nilly's Burgers"
      metaDescription="A hamburger restaurant serving homestyle burgers, fries, and drinks. We operate on short-order cooking and prepare our food in-house. Come visit our shop!"
    >
      <Home />
    </Layout>
  )
}
