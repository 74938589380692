import React, { memo } from 'react'
import { Box } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

interface IHomeBackgroundImage {}
const HomeBackgroundImage: React.FC<IHomeBackgroundImage> = () => {
  return (
    <Box
      className="home_bg-cover-container"
      width="100vw"
      height="inherit"
      minHeight="inherit"
    >
      <StaticImage
        src="../../../assets/images/home/nillys_burgers_cooking_img.jpg"
        className="home_bg-cover"
        alt="Nilly cooking burger patties"
        style={{
          width: '100vw',
          height: 'inherit',
          minHeight: 'inherit',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    </Box>
  )
}

export default memo(HomeBackgroundImage)
