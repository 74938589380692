import React, { useEffect, useRef, useState, memo } from 'react'
import { Box, IconButton, Icon } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { FaPlay, FaPause } from 'react-icons/fa'

import VideoThumbnail from '@/assets/images/home/nillys_video_thumbnail.jpg'
// Video Formats
import backgroundVideo from '@/assets/videos/nillys_background_video.mp4'

interface IHomeBackgroundVideo {
  isPlaying?: boolean
}
const HomeBackgroundVideo: React.FC<IHomeBackgroundVideo> = ({ isPlaying }) => {
  const videoRef = useRef(null)
  const [isVideoRendered, setIsVideoRendered] = useState<boolean>(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const [isVideoPlaying, setVideoPlaying] = useState(true)

  const togglePlay = () => {
    const video = videoRef.current
    if (video) {
      if (video.paused) playVideo(video)
      else pauseVideo(video)
    }
  }

  const playVideo = (video) => {
    setVideoPlaying(true)
    video.play()
  }

  const pauseVideo = (video) => {
    setVideoPlaying(false)
    video.pause()
  }

  useEffect(() => {
    const video = videoRef.current
    if (video && isVideoLoaded) {
      if (isPlaying) pauseVideo(video)
      else playVideo(video)
    }
  }, [isPlaying, isVideoLoaded])

  const onLoadedData = () => {
    setIsVideoLoaded(true)
  }

  useEffect(() => {
    const video = videoRef?.current
    if (video) setIsVideoRendered(true)

    return () => {
      if (video) pauseVideo(video)
    }
  }, [])

  return (
    <>
      <Box
        className="home_bg-cover-container"
        width="100vw"
        height="inherit"
        minHeight="inherit"
      >
        {!isVideoLoaded && (
          <Box height="inherit" minHeight="inherit">
            <StaticImage
              src="../../../assets/images/home/nillys_video_thumbnail.jpg"
              className="background-thumbnail"
              alt="Nilly cooking burger patties"
              style={{
                width: '100vw',
                height: 'inherit',
                minHeight: 'inherit',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
        )}
        <Box
          display={isVideoLoaded ? undefined : 'none'}
          height="inherit"
          minHeight="inherit"
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            id="backgroundVideo"
            className="home_bg-cover"
            ref={videoRef}
            onLoadedData={onLoadedData}
            width="100%"
            poster={VideoThumbnail}
          >
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </Box>
      </Box>
      {isVideoLoaded && (
        <IconButton
          className={`home_toggle-play-button ${
            isVideoPlaying ? 'playing' : 'paused'
          }`}
          icon={<Icon as={isVideoPlaying ? FaPause : FaPlay} />}
          aria-label={isVideoPlaying ? 'pause-icon' : 'play-icon'}
          size="lg"
          cursor="pointer"
          onClick={togglePlay}
          opacity={isVideoLoaded && isVideoRendered ? 1 : 0}
          isRound
        />
      )}
    </>
  )
}

export default memo(HomeBackgroundVideo)
