import { Button, Logo } from '@/components'
import { Box, Flex, Link, Text, useBreakpoint } from '@chakra-ui/react'
import loadable from '@loadable/component'
import React, { memo, useRef, useState } from 'react'
import HomeBackgroundImage from './HomeBackgroundImage'
import HomeBackgroundVideo from './HomeBackgroundVideo'

import { openFullscreen } from '@/utils/fullscreen-utils'
const PromoVideo = loadable(() => import('./PromoVideo'))
/**
 * The home page of the website
 * @returns the Home page component
 */
const Home: React.FC = () => {
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm' || bp === 'md'

  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPromoVidOpen, setIsPromoVidOpen] = useState(false)
  const closePromoVideo = () => setIsPromoVidOpen(false)

  return (
    <>
      <Box id="home" height="inherit" minHeight="inherit">
        {isMobile ? (
          <HomeBackgroundImage />
        ) : (
          <HomeBackgroundVideo isPlaying={isPromoVidOpen} />
        )}
        <Flex
          height={['calc(100vh - 3.5rem)', 'calc(100vh - 6rem)']}
          minHeight="inherit"
        >
          <Box className="home_centered">
            <Logo type={3} isLink={false} />
            <Text
              as="h1"
              textStyle="h2"
              color="common.white"
              fontSize={['1.6rem', '2rem', '2.3rem', '2.6rem']}
              fontWeight="bold"
              textTransform="uppercase"
              textShadow="0 0 2px rgba(0, 0, 0, 0.6)"
              lineHeight="1em"
              whiteSpace="nowrap"
              mt={10}
            >
              we make burgers
            </Text>
            <Link
              href="https://www.toasttab.com/nillysburgers/v3"
              target="_blank"
              _hover={{ textDecoration: 'none' }}
            >
              <Button
                width={['14rem', '17rem', '19rem', '22rem']}
                height="3rem"
                boxShadow="0 0 2px 0 rgba(0, 0, 0, 0.2)"
                mt={10}
              >
                <Text
                  textStyle="h3"
                  textTransform="uppercase"
                  fontSize={['1.4rem', '1.5rem', '1.6rem', '1.8rem']}
                  fontWeight="500"
                >
                  order now
                </Text>
              </Button>
            </Link>
            <Text
              textStyle="h3"
              textTransform="uppercase"
              transition="all 0.1s ease-in-out"
              fontSize={['0.9rem', '1rem']}
              fontWeight="light"
              color="common.white"
              cursor="pointer"
              mt={4}
              textDecoration="underline"
              textDecorationColor="transparent"
              textUnderlineOffset="3px"
              _hover={{
                textDecorationColor: 'common.white',
              }}
              onClick={() => {
                setIsPromoVidOpen(true)
                openFullscreen(videoRef.current, closePromoVideo)
              }}
            >
              {isMobile ? 'watch our video' : 'watch full video'}
            </Text>
          </Box>
        </Flex>
      </Box>
      <PromoVideo
        ref={videoRef}
        isOpen={isPromoVidOpen}
        setIsOpen={setIsPromoVidOpen}
      />
    </>
  )
}

export default memo(Home)
